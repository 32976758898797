<template>
  <div class="Receiving bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <!-- <div class="times">
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div> -->

        <div class="inputs">
          <el-input
            placeholder="输入客户电话"
            v-model="from.mobile"
            clearable
          ></el-input>
        </div>

        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        >
        <el-table-column
          prop="delivery_take_order_batch_member_no"
          align="center"
          label="合并批次号"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.store_delivery_take_order_batch">{{
              $empty.empty(
                scope.row.store_delivery_take_order_batch
                  .delivery_take_order_batch_no
              )
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="delivery_take_order_batch_member_no"
          align="center"
          label="客户批次号"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.delivery_take_order_batch_member_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="fullname" align="center" label="客户名称">
          <template slot-scope="scope">
            <span v-if="scope.row.member">{{
              $empty.empty(scope.row.member.fullname)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="客户电话">
          <template slot-scope="scope">
            <span v-if="scope.row.member">{{
              $empty.empty(scope.row.member.mobile)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="订单数量">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_number) }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="订单金额">
          <template slot-scope="scope">
            {{ tool.toDecimal2((scope.row.order_amt * 10000) / 100 / 10000) }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="订单毛重">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.gross_weight) }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="服务费合计">
          <template slot-scope="scope">
            {{
              tool.toDecimal2((scope.row.purchase_amt * 10000) / 100 / 10000)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="reveive_time" align="center" label="运费合计">
          <template slot-scope="scope">
            {{ tool.toDecimal2((scope.row.freight_amt * 10000) / 100 / 10000) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >查看详情</el-button
            >
            <el-button type="text" @click="printlist(scope.row)"
              >横版打印</el-button
            >
            <el-button type="text" @click="printlist(scope.row, 2)"
              >竖版打印</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="bottom-footer">
      <el-button @click="$router.back()">返回</el-button>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "../../api/index";
export default {
  name: "Receiving",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      role: sessionStorage.getItem("manageIsdentity"),
      from: {
        delivery_take_order_batch_id: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        delivery_take_order_batch_id: "",
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      value1: [],
      tableData: [],
      currentPage: 1,
      total: 0,
      multipleSelection: [],
      lsttables: [],
    };
  },
  created() {
    if (window.location.href.indexOf("&") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from.page);
      this.from.pageSize = Number(this.from.pageSize);
    }
    this.from.delivery_take_order_batch_id = this.$route.query.id
      ? this.$route.query.id
      : this.$route.query.delivery_take_order_batch_id;
    this.from1.delivery_take_order_batch_id = this.$route.query.id
      ? this.$route.query.id
      : this.$route.query.delivery_take_order_batch_id;
    this.hqlist();
  },
  methods: {
    getKey(row) {
      return row.id;
    },
    toggleSelection(rows) {
      console.log(rows, "进入了吗");
      this.$nextTick(() => {
        if (rows) {
          rows.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    printlist(el, type) {
      window.open(
        `${
          this.role == "delivery" ? BASE.PRO3 : BASE.PRO4
        }/take/order/batch/export` +
          `?delivery_take_order_batch_id=${this.$route.query.id}&token=${
            sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : ""
          }&member_id=${el.id}` +
          `${type ? "&print_type=2" : ""}`
      );
      this.hqlist();
      // this.$api.delivery.orderlisttiprint({ id: [el.id] }).then((res) => {
      //   console.log(res, "打印数据");
      //   this.hqlist();
      //   window.open(
      //     res.data +
      //       `?token:${
      //         sessionStorage.getItem("token")
      //           ? sessionStorage.getItem("token")
      //           : ""
      //       }`
      //   );
      // });
    },
    print() {
      const map = new Map();
      const qc = this.lsttables.filter(
        (key) => !map.has(key.id) && map.set(key.id, 1)
      );
      // console.log(qc,'数据')
      this.lsttables = qc;
      let arr = JSON.parse(JSON.stringify(this.lsttables));
      if (arr.length > 0) {
        window.open(
          `${
            this.role == "delivery"
              ? BASE.PRO3 + "/order/print"
              : BASE.PRO4 + "/take/order/print"
          }` +
            `?ids=${arr.map((el) => el.id).join(",")}&token=${
              sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : ""
            }`
        );
        this.lsttables = [];
        this.hqlist();
        // this.$api.delivery
        //   .orderlisttiprint({ id: arr.map((el) => el.id) })
        //   .then((res) => {
        //     console.log(res, "打印数据");
        //     this.lsttables = [];
        //     this.hqlist();
        //     window.open(
        //       res.data +
        //         `?token:${
        //           sessionStorage.getItem("token")
        //             ? sessionStorage.getItem("token")
        //             : ""
        //         }`
        //     );
        //   });
      } else {
        this.$message({
          type: "error",
          message: "请选择一条数据打印",
        });
      }
    },
    handleSelectionChange(val) {
      console.log(val, "1111111111");
      this.lsttables = val;

      this.multipleSelection = val;
    },
    search() {
      // if (this.value1 != null) {
      //   if (this.value1.length > 0) {
      //     this.from.startDate = this.$time.time(this.value1[0]);
      //     this.from.endDate = this.$time.time(this.value1[1]);
      //     console.log(this.from.startDate, this.from.endDate, "时间");
      //   } else {
      //     this.from.startDate = "";
      //     this.from.endDate = "";
      //   }
      // } else {
      //   this.from.startDate = "";
      //   this.from.endDate = "";
      // }
      this.from.page = 1;
      this.currentPage = 1;
      this.from1 = this.from;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },

    Refresh() {
      this.from = {
        delivery_take_order_batch_id: this.$route.query.id
          ? this.$route.query.id
          : this.$route.query.delivery_take_order_batch_id,
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        delivery_take_order_batch_id: this.$route.query.id
          ? this.$route.query.id
          : this.$route.query.delivery_take_order_batch_id,
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.lsttables = [];
      this.value1 = [];
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleEdit(row) {
      this.$router.push({
        path: `/ReceivingGoodsDetail`,
        query: {
          id: row.delivery_take_order_batch_id,
          member_id: row.id,
        },
      });
    },

    hqlist() {
      this.loading = true;
      console.log(this.from1);
      this.$api.delivery.batchmemberlist(this.from1).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.Receiving {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .bottom-footer {
    border-top: 1px solid #e8e8e8;
    background-color: #ffffff;
    display: flex;
    padding: 5px 0;
    box-sizing: border-box;
    justify-content: center;
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
</style>
